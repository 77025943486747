<template>
  <div style="padding: 15px">
    <div class="body" v-if="isShowDetails">
      <el-row style="border-bottom: 1px solid #bbb">
        <el-col :span="8">
          <h4>构件库</h4>
        </el-col>
        <el-col :span="16">
          <div class="serach">
            <!-- <p>已上线201个数字构件</p>
                        <p>708GB/2048GB</p> -->
            <el-select v-model="checkedStatus" size="mini" placeholder="按状态筛选" clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              v-model="search"
              style="width: 200px"
              size="mini"
              placeholder="请输入构件名字"
            ></el-input>
            <el-button type="info" size="mini" plain @click="searchList">搜索</el-button>
          </div>
        </el-col>
      </el-row>
      <!-- <div style="overflow: hidden;">
                <div class="headerList" :style="{ height: `${(level) * 45 + 30}px`, margin: '20px 10px' }">
                    <arr-list :data='data' @chartletList='chartletList' />
                </div>
            </div> -->
      <div style="text-align: left; margin: 20px">
        <category :categoryData="data" :events="events"></category>
      </div>
      <div class="gpsBtn" v-if="getuploadspaceData">
        <span
          class="one"
          :style="{
            backgroundColor: tabGps == item.id ? '#29303e' : '',
            color: tabGps == item.id ? 'white' : '',
          }"
          @click="tabComponent(item.id)"
          v-for="item in getuploadspaceData"
          :key="item.id"
        >
          {{ item.name }}
          <el-tooltip
            v-if="item.name == '智装荟公库'"
            class="item"
            effect="dark"
            content="放置此位置的为智装荟公共空间，构件对所有人/企业开放预览或调用"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </span>
      </div>
      <div class="componentList">
        <el-card class="box-card" shadow="hover" v-for="(it, i) in componentList" :key="i">
          <div class="componentList_body">
            <div class="componentList_body_img">
              <!-- <img style="width: 200px; height: 200px" :src="it.modelIcon" alt=""
                                @click="isShowDetails = false"> -->
              <img
                style="width: 200px; height: 200px; object-fit: cover"
                :src="it.modelIcon"
                alt=""
              />
              <div class="statusBtn">
                <el-button size="mini" v-if="'使用中' == it.status" type="primary" plain>
                  启用中
                </el-button>
                <el-button size="mini" v-if="'审核中' == it.status" type="success" plain>
                  审核中
                </el-button>
                <!-- <el-button size="mini" v-if="status == it.status" type="warning" plain>待启用</el-button> -->
                <el-button size="mini" v-if="'停用' == it.status" type="info" plain>
                  停用中
                </el-button>
              </div>
              <div class="editBtn" v-if="'审核中' != it.status">
                <!-- <span class="btn">编辑</span> -->
                <span class="btn" @click="modelSetstatus(it.id)">
                  {{ it.status_value == 1 ? '禁用' : '启用' }}
                </span>
              </div>
            </div>
            <!-- <div class="componentList_body_data" @click="isShowDetails = false"> -->
            <div class="componentList_body_data">
              <h6>{{ it.modelName }}</h6>
            </div>
          </div>
        </el-card>
      </div>
      <div style="text-align: center; margin: 25px 0">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.page"
          :page-sizes="[10, 15, 30, 35]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.totalItems"
        ></el-pagination>
      </div>
    </div>
    <div v-else>
      <cloud-details />
    </div>
  </div>
</template>

<script>
import { component_list } from '../../common/cloud';
import { model_setstatus } from '../../common/modelList';
import { getuploadspace } from '../../common/memory';
import { modelsConfig_list } from '@/common/modelList';
// import arrList from '../../components/arrList.vue'
// import Bus from '@/common/bus.js';
import CloudDetails from './component/cloudDetails.vue';
import Category from '../../components/category.vue';
export default {
  components: {
    // arrList,
    CloudDetails,
    Category,
  },
  name: 'cloud',
  data() {
    let that = this;
    return {
      events: {
        selectAllEvent(data) {
          const { id } = data;
          that.chartletList(id);
        },
        selectSingleEvent(data) {
          const { id } = data;
          that.chartletList(id);
        },
      },
      // 选中状态
      checkedStatus: '',
      // 关键词
      search: '',
      // 体系数据
      data: [],
      // 搜索状态数据
      options: [
        {
          value: 1,
          label: '启用中',
        },
        {
          value: 2,
          label: '禁用中',
        },
        {
          value: 3,
          label: '审核中',
        },
      ],
      // 层级
      level: '',
      // 显示隐藏
      isShow: false,
      // 选中的ID
      lastClassifyId: 0,
      // 空间ID
      tabGps: 0,
      // 构件状态
      status: 0,
      // 分页
      page: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
      },
      // 是否显示详情
      isShowDetails: true,
      // 空间数据
      getuploadspaceData: [],
      // 构件数据
      componentList: [],
    };
  },
  created() {
    this.init();
    // Bus.$on('getTarget', (data, isShow, level) => {
    //     this.chartletList(data.id)
    //     this.isShow = isShow
    //     this.level = level
    // });
  },
  methods: {
    // 分页
    handleSizeChange(size) {
      this.page.pageSize = size;
      this.init();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.init();
    },
    // 初始化
    init() {
      modelsConfig_list().then((res) => {
        if (res.code == 200) {
          this.data = res.result.config_list;
          this.data.splice(0, 0, { name: '所有体系', show: 0, id: 0, level: 1, children: [] });
          this.data.forEach((e) => {
            this.addAll(e);
          });
        }
      });
      getuploadspace().then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.getuploadspaceData = result;
          this.getuploadspaceData.unshift({ id: 0, name: '所有空间', file_path: '' });
        }
      });
      this.initData();
    },
    // 在每个数组前添加所有字段
    addAll(e) {
      if (e.children != '') {
        e.children.splice(0, 0, {
          name: '所有分类',
          show: 0,
          id: e.id,
          level: e.level + 1,
          children: [],
        });
        e.children.forEach((el) => {
          this.addAll(el);
        });
      } else {
        return;
      }
    },
    // 切换列表
    chartletList(classData) {
      this.lastClassifyId = classData;
      this.initData();
    },
    // 切换公库
    tabComponent(item) {
      this.tabGps = item;
      this.initData();
    },
    searchList() {
      this.page.page = 1;
      this.initData();
    },
    // 刷新数据
    initData() {
      let dt = {
        classify_id: this.lastClassifyId,
        space_id: this.tabGps,
        search: this.search,
        status: this.checkedStatus,
        page: this.page.page,
        pageSize: this.page.pageSize,
      };
      component_list(dt).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.componentList = result.info;
          this.page.totalItems = result.total;
        }
      });
    },
    // 修改状态
    modelSetstatus(e) {
      model_setstatus({ model_id: e }).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.$message.success(msg);
          this.initData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  border-radius: 5px;
  box-shadow: 0 0 4px 1px #bbb;
  padding: 1px;
  background-color: white;
  min-width: 1000px;
  display: flex;
  flex-direction: column;

  h4 {
    margin: 0;
    padding: 15px;
  }

  .serach {
    display: flex;
    align-items: center;
    float: right;
    padding: 15px 15px;
    font-size: 12px;

    p {
      margin: 20px 10px;
    }

    .el-select,
    .el-input {
      margin-right: 10px;
    }

    .el-button {
      background-color: #29303e;
      color: white;
    }
  }

  .headerList {
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 20px 10px;
    padding: 0px 0 20px;
  }

  .gpsBtn {
    margin: 0 10px;
    font-size: 12px;

    .one {
      margin: 0 10px;
      background-color: #eeeeee;
      padding: 5px 20px;
      border-radius: 20px;

      .item {
        font-size: 14px;
        color: #a6a6a6;
        padding-left: 5px;
      }
    }
  }

  .componentList {
    margin: 30px 10px 0;

    .box-card {
      width: 200px;
      margin: 10px;
      display: inline-block;

      ::v-deep .el-card__body {
        padding: 0;
      }
    }

    .componentList_body {
      display: inline-block;
      width: 200px;
      height: 260px;

      .componentList_body_img {
        width: 200px;
        height: 200px;
        background-color: #f0f0f0;
        position: relative;

        .statusBtn {
          position: absolute;
          top: 10px;
          right: 10px;

          .el-button--mini {
            padding: 5px 10px;
          }
        }

        .editBtn {
          background-color: rgba(0, 0, 0, 0.7);
          color: white;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-size: 12px;
          padding: 10px 0;
          opacity: 0;

          .btn {
            cursor: pointer;
          }
        }
      }

      .componentList_body_img:hover .editBtn {
        opacity: 1;
      }

      .componentList_body_data {
        padding: 0 10px;

        h6 {
          font-size: 14px;
          margin: 15px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
