import $http from '@/utils/http';

/**
 * 构件管理
 * @param data
 * @returns {Promise<unknown>}
 */
export function component_list(data) {
  return $http.get('/api/digital_component/component_list', data, 'loadingDiv');
}
